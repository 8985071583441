
  interface ConfigLoja {
    id: number;
    produtosPrincipal: any[];
    layoutApp: any[];
    tipoApp: number;
    tipoOferta: string;
    textoLegal: string;
    assinatura: string;
    rodapeOrcamento: string;
    buscaPlaca: any;
    alterarValor: any;
    formRecepcao: any;
    valorMO: any;
    oleoFracionado: any;
    podeDesconto: any;
    assinaturaCliente: any;
    estoqueNegativo: any;
    fornecedorBuscaPlaca: string;
    dadosExtras: any[];
    limites: {
      whatsapp: any;
      buscaPlaca: any;
    };
  }

  interface ConfigLojaAtt {
    produtosPrincipal: number;
    tipoApp: number;
    tipoOferta: string;
    textoLegal: string;
    assinatura: string;
    rodapeOrcamento: string;
    buscaPlaca: string;
    alterarValor: string;
    formRecepcao: string;
    valorMO: string;
    oleoFracionado: string;
    podeDesconto: string;
    assinaturaCliente: string;
    estoqueNegativo: string;
    fornecedorBuscaPlaca: string;
    limites: {
      whatsapp: any,
      buscaPlaca: any
    }
  }

  import useEmitter from "@/composables/Emmiter";
  import Api from "@/services/Api";
  import { defineComponent, onMounted, ref, Ref, watch } from "vue";
import { config } from "vuex-module-decorators";

  export default defineComponent({
    name: "ConfigLojas",
    emits: [],
    props: {},
    setup() {
      const emitter = useEmitter();
      const produtoselect: Ref<any> = ref("");
      const layoutAppRef: Ref<any> = ref("");
      const options: Ref<any> = ref([
        { name: "denatran", id: 1 },
        { name: "suiv", id: 2 },
      ]);

      const configLoja: Ref<ConfigLoja> = ref({
        id: 0,
        produtosPrincipal: [],
        layoutApp: [],
        tipoApp: 0,
        tipoOferta: "",
        textoLegal: "",
        assinatura: "",
        rodapeOrcamento: "",
        buscaPlaca: "",
        fornecedorBuscaPlaca: "",
        alterarValor: "",
        formRecepcao: "",
        valorMO: "",
        oleoFracionado: "",
        podeDesconto: "",
        assinaturaCliente: "",
        estoqueNegativo: "",
        dadosExtras: [],
        limites: {
          buscaPlaca: null,
          whatsapp: null,
        },
      });

      const LojaAtt: Ref<ConfigLojaAtt> = ref({
        produtosPrincipal: 0,
        tipoApp: 0,
        tipoOferta: "",
        textoLegal: "",
        assinatura: "",
        rodapeOrcamento: "",

        buscaPlaca: "",
        fornecedorBuscaPlaca: "",
        oleoFracionado: "",
        podeDesconto: "",

        alterarValor: "",
        formRecepcao: "",
        valorMO: "",
        assinaturaCliente: "",
        estoqueNegativo: "",
        limites : {
          whatsapp: null,
          buscaPlaca: null
        }
      });

      const camposObrigatoriosMensagem: Ref<any> = ref({
        nomeFantasia: false,
        cnpj: false,
        razaoSocial: false,
        produtosPrincipal: false,
        tipoApp: false,
        tipoOferta: false,
        grupo: false,
      });

      function configLojaAtt() {
        
        try {
          LojaAtt.value = {
            produtosPrincipal: produtoselect.value,
            tipoApp: layoutAppRef.value ? layoutAppRef.value : "",
            tipoOferta: configLoja.value.tipoOferta,
            textoLegal: configLoja.value.textoLegal,
            assinatura: configLoja.value.assinatura,
            rodapeOrcamento: configLoja.value.rodapeOrcamento,
            buscaPlaca: configLoja.value.buscaPlaca,
            alterarValor: configLoja.value.alterarValor,
            formRecepcao: configLoja.value.formRecepcao,
            valorMO: configLoja.value.valorMO,
            oleoFracionado: configLoja.value.oleoFracionado,
            podeDesconto: configLoja.value.podeDesconto === "Não" ? "N" : "S",
            assinaturaCliente: configLoja.value.assinaturaCliente,
            estoqueNegativo: configLoja.value.estoqueNegativo,
            fornecedorBuscaPlaca: configLoja.value.fornecedorBuscaPlaca,
            limites : configLoja.value.limites
          };

        } catch (error) {
          console.log(error);
        } finally {
          enviarEmit();
        }
      }

      async function getPadrao() {
        const { data } = await Api.get("buscaDadosParaNovaLoja");
        const { bandeiras, produtosPrincipais } = data;
      
        configLoja.value = {
          ...configLoja.value,
          layoutApp: bandeiras,
          produtosPrincipal: produtosPrincipais,
        };
      }

      async function enviarEmit() {
        await emitter.emit("GetLojaAtualizada", {
          ConfigLojaAtualizada: [LojaAtt.value],
        });
      }

      emitter.on("GetDadosLoja", async (params) => {

        if (!params.DadosLoja[3].produtosPrincipal.length) {
          return;
        }

        configLoja.value = params.DadosLoja[3];

        if(configLoja.value.produtosPrincipal && configLoja.value.produtosPrincipal.length > 0) {
          produtoselect.value = configLoja.value.produtosPrincipal[0];
        }

        if (configLoja.value.layoutApp && configLoja.value.layoutApp.length > 0) {
          const bandeiraAtual = configLoja.value.layoutApp.find(
            (item) => item.codBandeira === configLoja.value.tipoApp
          );

          layoutAppRef.value = bandeiraAtual.codBandeira;
        }

      });

      emitter.on("Campos obrigatorios", async (params) => {
        //verificando se os campos obrigatorios estão preenchidos ou não
        camposObrigatoriosMensagem.value = params.camposObrigatorios.value;
      });

      watch(
        () => [produtoselect.value, layoutAppRef.value, configLoja.value],
        (newValues) => configLojaAtt(),
        { deep: true }
      );

      onMounted(() => getPadrao());

      return {
        configLoja,
        produtoselect,
        layoutAppRef,
        camposObrigatoriosMensagem,
        options,
      };
    },
  });
